/**
 * Created by Hp on 14/03/2017.
 */
(function () {
    "use strict";

    let dialog = {
        controller: DialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/out-lots-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        focusOnOpen: false,
        multiple: true
    };


    DialogCtrl.$inject = ["$mdDialog", "$scope", "system", "lotService", "$q", "$translate"];

    function DialogCtrl($mdDialog, $scope, system, lotService, $q, $translate) {

        let vm = this;
        let dateFormat = system['date_format'].js;
        vm.cancel = $mdDialog.cancel;

        vm.saveLot = saveLot;
        vm.lotsByArticle = null;
        vm.getOutOrder = getOutOrder;
        vm.selectNew = selectNew;

        vm.maxQte = maxQte;
        vm.removeLine = removeLine;
        vm.$onInit = init;
        vm.selectedLot = null;
        vm.sumQte = sumQte;
        vm.overQte = overQte;
        vm.selectItem = selectItem;
        vm.isSelected = isSelected;
        vm.isAllSelected = isAllSelected;
        vm.applyFilter = applyFilter;
        vm.getLotCurrentStoreQte = getLotCurrentStoreQte;

        function init() {
            vm.lotQuery = {
                limit: 4,
                page: 1,
                order: "+code"
            };
            vm.searchKey = null;
            vm.filteredLots = [];
            vm.options = [5, 10, 15];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }
            vm.lotsByArticle = [];
            vm.lotsByArticleCopy = [];
            vm.promise = lotService.getAvailableLots(_.get(vm.line, 'warehouse.id'), _.get(vm.line, 'article.id'), vm.mvt_type, vm.sourceDoc, _.get(vm.line, 'uid')).then(success);

            function success(data) {
                vm.lotsByArticle = data;
                vm.lotsByArticleCopy = data;
            }

        }

        //*************************************************** in lot ***************************************************
        function addNew() {
            vm.lots.push({
                stores: [{warehouse: vm.line.warehouse, qte: 1}],
                expiration_date: moment().format(dateFormat),
                article: vm.line.article
            });
        }

        function saveLot() {
            $mdDialog.hide(vm.lots);
        }

        function getOutOrder() {
            let order = 'expiration_date';
            switch (vm.line.article.stock_valuation) {
                case 'PMP':
                case 'FIFO':
                    order = 'created_at';
                    break;
                case 'FEFO':
                    order = 'expiration_date';
                    break;
                case 'LIFO':
                    order = '-created_at';
                    break;
                default :
                    order = 'created_at';
            }
            return order;
        }

        function selectNew(obj) {
            if (vm.mvt_type === 'WarehouseExchange') {
                if (_.isNil(_.find(vm.lots, function (item) {
                    return item.lot['code'] === obj.code;
                }))) vm.lots.push({lot: obj, transfer_qte: obj['lot_qte']});
            } else {
                let sold_qte = _.find(vm.currentLotStore(obj, vm.line)[vm.mvt_type === 'Consignment' ? 'consigned_quantities' : 'sold_quantities'], {
                    mvt_code: vm.mvt_code,
                    mvt_cls: vm.documentCls
                });
                if (_.isNil(sold_qte)) {
                    vm.currentLotStore(obj, vm.line)[vm.mvt_type === 'Consignment' ? 'consigned_quantities' : 'sold_quantities'].push({
                        qte: obj['lot_qte'],
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                }
                vm.lots.push(obj);
            }
            _.remove(vm.lotsByArticle, {code: obj.code});
        }

        function removeLine(idx) {

            return vm.lots.splice(idx, 1);

        }

        function sumQte() {
            return _.sumBy(vm.lots, 'store_qte');
        }

        function overQte() {
            return vm.limit <= _.sumBy(vm.lots, 'store_qte');
        }

        function selectItem(lot) {
            let q = vm.limit - _.sumBy(vm.lots, 'store_qte');
            if (!vm.overQte()) {
                this.lots.push(_.assignIn(_.omit(lot, 'deposition_qte', 'consigned_qte', 'sold_qte', 'return_qte'),
                    {'store_qte': _.get(lot, 'store_qte') < q ? _.get(lot, 'store_qte') : q}));
            }
        }

        function isSelected(lot) {
            return _.find(vm.lots, {code: lot.code});
        }

        function getLotCurrentStoreQte(lot) {
            let original_l = _.find(vm.lotsByArticleCopy, {code: lot.code});
            return (_.get(original_l, 'store_qte', 0) + _.get(original_l, 'deposition_qte', 0)) - (_.get(original_l, 'consigned_qte', 0)
                + _.get(original_l, 'return_qte', 0) + _.get(original_l, 'sold_qte', 0));
        }

        function maxQte(idx) {
            let original_m = getLotCurrentStoreQte(vm.lots[idx]);
            let m = vm.limit - _.sumBy(_.reject(vm.lots, (e, i) => {
                return idx == i;
            }), 'store_qte');

            if (vm.mvt_type ==='DeliveryReturn') {
                return m;
            }

            return original_m > vm.limit ? m : original_m;
        }

        function isAllSelected() {
            return _.every(vm.lotsByArticle, (l) => {
                return _.find(vm.lots, {code: l.code});
            })
        }

        function applyFilter() {
            vm.filteredLots = _.filter(vm.lotsByArticle, item => {
                return _.isMatch(item['code'], vm.searchKey);
            });
        }
    }

    module.exports = dialog;
})();