/**
 * Created by amine on 11/02/2016.
 */
(function () {
    'use strict';

    const {BehaviorSubject} = require("rxjs");

    class ConfigService {
        constructor($q, mnWebSocket, $http, system) {
            this.q = $q;
            this.http = $http;
            this.ws = mnWebSocket;

            this.defaultValues = {};
            this.activeModules = _.get(system, "active_modules", {});
            this.sideNaveSubject = new BehaviorSubject(null);
            this.toolbarLinks = new BehaviorSubject(null);
            this.calendarConfig={};
        }

        static get $inject() {
            return ["$q", "mnWebSocket", "$http", "system"];
        }

        get(keys, mine, speciality, event) {
            if (!_.isArray(keys)) {
                if (_.isString(keys)) keys = _.castArray(keys);
                else return false;
            }

            if (_.isUndefined(mine)) mine = true;
            if (_.isUndefined(speciality)) speciality = false;
            if (_.isUndefined(event)) event = "shared.Config.get_config";

            const deferred = this.q.defer();
            const success = (data) => {
                deferred.resolve(
                    keys.length > 1 ? _.reduce(data, (obj, item) => _.assign({}, obj, {[item.key]: item.value}), {}) : _.get(data, '[0].value')
                );
            }


            this.ws.call(event, {keys, mine, speciality})
                .then(success, deferred.reject);

            return deferred.promise;
        }

        set(config, mine = false, speciality = false, event) {
            if (!_.isObject(config)) return false;

            if (_.isNil(event)) event = "shared.Config.set_config";

            return this.ws.call(event, {config, mine, speciality});
        }

        getDefaultValues() {
            return this.get('default_values')
                .then((data) => this.defaultValues = data);
        }

        getByHttp(key) {
            const deferred = this.q.defer();
            const url = `/api/general-config-key/?key=${key}`;

            this.http.get(url)
                .then(
                    response => deferred.resolve(_.get(response, 'data.value')), deferred.reject
                );

            return deferred.promise;
        }

        checkActiveModule(key) {
            return _.get(this.activeModules, key, false);
        }

        isDental() {
            return this.checkActiveModule('has_dental');
        }

        hasMedicalCare() {
            return this.checkActiveModule('has_medical_care');
        }

        hasStock() {
            return this.checkActiveModule('has_stock');
        }

        hasCareSheet() {
            return this.checkActiveModule('has_care_sheet');
        }

        hasAdvanceVisitFinancial() {
            return this.hasStock() && _.get(this.activeModules, 'has_adv_visit_financial', false);
        }

        hasVideoCall() {
            return _.get(this.activeModules, 'has_video_call', false);
        }

        hasForms() {
            return _.get(this.activeModules, 'has_forms', false);
        }

        hasCam() {
            return this.checkActiveModule('has_cam');
        }

        getToolbarLinksConfig() {
            return this.get("toolbar_links_config", true)
                .then(data => {
                    if (!!data) {
                        this.toolbarLinks.next(data);
                    } else {
                        this.toolbarLinks.next({forms: [], order: []});
                    }
                });
        }

        getCalendarConfig() {
            return this.getByHttp("calendar_config").then((data) => this.calendarConfig = data);
        }

    }

    module.exports = ConfigService;

})();
