(function () {

    'use strict';

    class CustomerOrderFormCtrl {
        constructor(
            $transition$, $scope, stockService, $mdDialog, system, $state, $mdToast, $translate, purchaseService, configService, $q, moment) {
            this.stockService = stockService;
            this.state = $state;
            this.scope = $scope;
            this.moment = moment;
            // this.ws = mnWebSocket;
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.purchaseService = purchaseService;
            this.configService = configService;
            this.$q = $q;

            this.currentOrder = $transition$.params('to')["orderId"];
            this.dateFormat = system['date_format'].js;
            this.submit = this.submit.bind(this);
            this.readOnlyOrder = this.readOnlyOrder.bind(this);
            this.patientCallback = this.patientCallback.bind(this);
            this.saveSuccess = this.saveSuccess.bind(this);
            this.save = this.save.bind(this);
            this.invalidateOrder = this.invalidateOrder.bind(this);
            this.validateOrder = this.validateOrder.bind(this);

        }


        static get $inject() {
            return ["$transition$", "$scope", "stockService", "$mdDialog", "system", "$state", "$mdToast", "$translate", "purchaseService", "configService", "$q", "moment"];
        }


        $onInit() {
            this.is_new = _.isNil(this.currentOrder);
            this.docType = 'CBC';
            this.scope.$watch('this.order.is_valid', this.readOnlyOrder);
            this.modelName = 'CustomerOrderForm';
            this.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};

            this.promises = [this.configService.get(["price_mode"], true)];

            if (!_.isNil(this.currentOrder)) this.promises.push(this.stockService.getStockDocument(this.currentOrder, 'customer-order-form'));
            else this.order = {doc_date: this.moment().format(this.dateFormat), lines: []};
            this.promise = this.$q.all(this.promises).then(success.bind(this));

            function success(data) {
                if (!_.isNil(this.currentOrder)) this.order = data[1];
                else this.order.price_mode = data[0];
            }
        }

        submit(with_cancel) {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.promise = this.save().then((data) => {
                    this.prevent_save = false;
                    if (with_cancel) window.history.back();
                    else {
                        this.saveSuccess(data);
                    }
                }, (err) => {
                    this.prevent_save = false;

                });
            }

            // function success(data) {
            //
            // }
            //
            // function error(err) {
            // }
        }

        saveSuccess(data) {
            this.order = data;
            if (_.isNil(this.currentOrder)) this.state.go('app.stock.customer-order-form', {orderId: data.id}, {location: 'replace'});
        }

        invalidateOrder() {
            return this.stockService.invalidateMovement(_.get(this.order, 'id'), 'CustomerOrderForm', this.docType);
        }

        validateOrder(data) {
            this.order.is_valid = true;
            return this.save();
        }

        readOnlyOrder() {
            this.readOnly = (this.order && this.order.is_valid) ? this.order.is_valid : false;
        }

        save() {
            return this.stockService.saveStockDocument(this.order, "customer-order-form", 'CustomerOrderForm');
        }

        removePatient() {
            this.order.patient = null;
        }

        patientCallback(patient) {
            this.order.patient = patient;
        }

        handleState(state) {
            this.stockService.updateStockDocument(this.order.id, "customer-order-form", _.zipObject([state], [true]))
        }
    }

    module.exports = CustomerOrderFormCtrl;

})();
